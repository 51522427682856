@import "ClientApp/styles/base/base.scss";
@import 'keen-slider/keen-slider.scss';

.navigation-wrapper {
  position: relative;

  .dots {
    display: none;
  }


  .arrow {
    width: auto;
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    fill: #41a457;
    cursor: pointer;
    transition: all 100ms linear;
    padding: 0 1.5rem;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);

    &:hover {
      &.arrow--right,
      &.arrow--left {
        background-size: 20px;
      }
    }
  }

  .arrow--left,
  .arrow--right {
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
  }

  .arrow--left {
    left: 0;
    right: auto;
    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' %3E%3Cpath d='M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z' %3E%3C/path%3E%3C/svg%3E");
    box-shadow: 1px 0px 8px rgb(255, 255, 255, 0.9);
  }

  .arrow--right {
    left: auto;
    right: 0;
    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' %3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'%3E%3C/path%3E%3C/svg%3E");
    box-shadow: -1px 0px 8px rgb(255, 255, 255, 0.9);
  }

  .arrow--disabled.arrow--right,
  .arrow--disabled.arrow--left {
    display: none;
  }
}

